import React from 'react';
import axios from 'axios';
import http from '../modules/api';
import {NavLink, Route, Switch} from "react-router-dom";
import {AdminUserDetail} from "./AdminUserDetail";
import DatePicker from "react-datepicker";
import Pagination from "react-js-pagination";
import Select from 'react-select';
import {reactSelectStyles} from '../modules/libraries/react-select';
import makeAnimated from 'react-select/animated';
import FileDownloadComponent from "../modules/FileDownloadComponent";
import $ from 'jquery';
import "react-datepicker/dist/react-datepicker.css";
import CheckBox from '../modules/common/CheckBox';
import { ko } from "date-fns/esm/locale"; // datePicker 한글화
import ConfirmDialogV2 from '../modules/dialogs/ConfirmDialogV2';
import BasicDialogV2 from '../modules/dialogs/BasicDialogV2';
import SaveDialogV2 from '../modules/dialogs/SaveDialogV2';

const animatedComponents = makeAnimated();

export class AdminUserFreeTrialExtensionList extends React.Component {
	
	state = {
			list: [],
			login_log_list: [],
			shop_products_total_count_list: [],
			shop_total_sales_list: [],
			shop_visit_count_list: [],
			setting_service_type: "",
			setting_added_type: "Y",
			setting_free_date: "",
			setting_free_date_format: "",
			active_service_list: [],

			request_type_list: [],
			payment_type_list: [],
			payment_service_type: "",
			user_service_payment_type_list: [],

			banner_total_count_list: [],
			rp_total_count_list: [],
			banner_shop_list: [],
			rp_shop_list: [],
			selected: "",
			orderGubun: "",
			sorting: "",
			currentPage: "",
			start_date: "",
			end_date: "",
			start_date_format: "",
			end_date_format: "",
			title: "",
			activePage: 1,
			totalCnt: 0,
			startIdx: 0,
			popup_activePage: 1,
			popup_totalCnt: 0,
			popup_startIdx: 0,
			rowcnt:10,
			popup_rowcnt:10,
			options:[],
			popup_cls: "",
			user_state_list : [],
			user_scale_list : [],
			user_manager_list : [],
			modRemarkList : [],
			filter_function_cd : "",	
			
			additional_function_list: [],

			aditional_service_delete_dialog_show_state: false,
			aditional_service_delete_dialog_content: "",
			aditional_service_delete_dialog_button_list: [],

			basic_dialog_show_state: false,
			basic_dialog_content: "",
			basic_dialog_button_list: [],

			show_save_loading_dialog: false,
			save_dialog_content: "",
			save_dialog_button_list: [],
	}
	
	componentDidMount() {
		$("#modalBack").hide(); 		// 모달 배경 숨김
		$("#modal_popup").hide(); 		// 모달 숨김
		//$("#modal_login_log").hide(); // 모달 숨김
		if (localStorage.getItem ('state') != null){
			new Promise( ( resolve, reject ) => {setTimeout( resolve, 200 ); })
            .then( () => {
				var json = JSON.parse(localStorage.getItem ('state'));
				json.list = [];
				json.start_date = Date.parse(json.start_date_format);
				json.end_date = Date.parse(json.end_date_format);
				json.setting_free_date = ""; // 무료체험기간을 수정했더라도 localStorage에서 꺼내 쓸 땐 초기화 해줌. (원래 state에 남아 있으면 안되는 값인데 무료체험기간 수정 후 팝업창을 닫지 않으면 값이 남아있어서 초기화 필요함.)
				this.setState(json);
    			this.state.startIdx = parseInt(localStorage.getItem("startIdx"));
    			localStorage.clear();
    			
    			this.getUserList();
            });
		} else {
			this.getUserList();
			this.getAdditionalFunctionList(); // 부가서비스 목록
		}

		let this_component = this;
		$(".modalClose").click(function() {
            $("#modalBack").hide();
            $("#modal_popup").hide();
            $("body").css("background-color", "white");
            $("#modalBack").css("background-color", "white");
            $("#page-wrapper").css("background-color", "white");

			// 팝업내 사용자 세팅 관련 초기화
			this_component.setState({
				target_user_seq: '',
				setting_service_type: '',
				//added_type: "Y",	// 관리자 설정시 활성 고정
				setting_free_date: '',
			})
        });
	}

	componentDidUpdate () {
		let remarkAreas = document.querySelectorAll(".user_remark_text_area");
		console.log("###### remarkAreas", remarkAreas);
		if (remarkAreas && remarkAreas.length > 0) {
			for (let idx in remarkAreas) {
				if (remarkAreas[idx].style != null) {
					this.textAreaAutoResize(remarkAreas[idx]);
				}
			}
		}
	}

	componentWillUnmount () {
		$("#modalBack").hide();
		$("#modal_popup").hide();
		$("body").css("background-color", "white");
		$("#modalBack").css("background-color", "white");
		$("#page-wrapper").css("background-color", "white");
	}

	// 부가 서비스 코드 조회
	async getAdditionalFunctionList () {
		const res = await http.get("/rest/common/list/additionalFunction");
		let list = res.data.list;

		let additional_function_list = [];
		let full_idx = 0;
		for (let idx in list) {
			additional_function_list[idx] = {
				code_cd : list[idx]["function_cd"]+"_N",
				code_name : list[idx]["function_name"]+" 요청"+" ("+list[idx]["val2"]+")",
			}
			full_idx ++;
		}
		for (let idx in list) {
			additional_function_list[Number(full_idx)+Number(idx)] = {
				code_cd : list[idx]["function_cd"]+"_Y",
				code_name : list[idx]["function_name"]+" 결제"+" ("+list[idx]["val2"]+")",
			}
		}

		this.setState({
			additional_function_list: additional_function_list
		})
	}

	//공통코드 조회
	async getCode(cd_class) {
	    const res = await http.get("/rest/common/roboMdCode?cd_class="+cd_class);
		// alert(JSON.stringify(res.data))
		if (cd_class == "2200") {
			this.setState({options:res.data});
		} else if (cd_class == "USTATE01") {
			let user_state_list = res.data ? res.data.slice() : [];
			user_state_list.shift()
			this.setState({
				user_state_filter_list:res.data,
				user_state_list:user_state_list
			});
		} else if (cd_class == "USCALE01") {
			this.setState({user_scale_list:res.data});
		} else if (cd_class == "UMNG01") {
			this.setState({user_manager_list:res.data});
		}
	};

	//결제관련 공통코드 조회
	async getOrderCode(upper_code_cd) {
	    const res = await http.get("/rest/common/roboMdOrderCode?upper_code_cd="+upper_code_cd);
		// alert(JSON.stringify(res.data))
		if (upper_code_cd == "05000") {
			this.setState({ request_type_list : res.data });
		}
	};
	
	//1. 사용자 목록 조회
	async getUserList() {
//        const res = await http.get("/rest/admin/adminUserFindAllForAdditionalFunction", {params:{user_id:''}});
//        this.setState(res.data);
        await http.get("/rest/admin/adminUserFreeTrialExtensionList"
																		+"?title="+this.state.title
																		+"&start_date="+this.state.start_date_format
        											 					+"&end_date="+this.state.end_date_format
																		+"&order_type="+this.state.sorting
																		+"&order_gubun="+this.state.orderGubun
																		+"&startidx="+this.state.startIdx
																		+"&rowcnt="+this.state.rowcnt
																	)
        		   .then(res => {


							/* 비고 */
							this.setState( {list : res.data["list"]} );
							this.setState( {totalCnt : res.data["totalCnt"]} );
							
							for (var idx in res.data["list"]) {
								/* 비고 */
								let remark_name = res.data["list"][idx].user_free_trial_extensions_seq + "_remark";
								let remark_value = res.data["list"][idx].remark;
								this.setState({
									[remark_name] : remark_value,
								})
							}
							
							// console.log(this.state.totalCnt)
    			   			// console.log(this.state.activePage)
    			   			// console.log(this.state.startIdx)
        		   })
        		   .catch(error => {
        			   		// console.log (error);
        		   });
        
    }
	//2. 사용자 상세 조회 이벤트
	handleClick = (user_id) => {
		this.props.history.push(`/admin/userFind/${user_id}`);
		localStorage.setItem ( 'state' , JSON.stringify(this.state) ) ;
		// localStorage.setItem ( 'activePage' , this.state.activePage ) ;
		localStorage.setItem ( 'startIdx' , this.state.startIdx ) ;
	};
	// 정렬
	onClickSorting = (e) => {
		if (this.state.orderGubun == e.target.className.replace(" sorting","")) {
			if (this.state.sorting == "ASC")
				this.state.sorting = "DESC";
			else
				this.state.sorting = "ASC";
		} else {
			this.state.sorting = "DESC";
		}
		this.state.orderGubun = e.target.className.replace(" sorting","");
		this.state.currentPage = 1;
		this.getUserList();
	};
	//3. 사용자 조회 버튼 이벤트
	onClickSearchListButton = () => {
		this.state.startIdx = 0;
		this.state.activePage = 1;
		this.getUserList();
	};
	//4. 달력 클릭
	handleChangeStartDate = date => {
		console.log(date);
		if (date != null) {
			this.setState({
				start_date_format: date.getFullYear() + "-"+ ("0" + parseInt(date.getMonth()+1)).slice(-2) +"-"+("0" + parseInt(date.getDate())).slice(-2),
				start_date: date
			});
		} else {
			this.setState({
				start_date_format: date,
				start_date: date
			});
		}
	};
	handleChangeEndDate = date => {
		console.log(date);
		if (date != null) {
			this.setState({
				end_date_format: date.getFullYear() + "-"+ ("0" + parseInt(date.getMonth()+1)).slice(-2) +"-"+("0" + parseInt(date.getDate())).slice(-2),
				end_date: date
			});
		} else {
			this.setState({
				end_date_format: date,
				end_date: date
			});
		}
	};
	handleChangeSettingFreeDate = date => {
		console.log(date);
		if (date != null) {
			this.setState({
				setting_free_date_format: date.getFullYear() + "-"+ ("0" + parseInt(date.getMonth()+1)).slice(-2) +"-"+("0" + parseInt(date.getDate())).slice(-2),
				setting_free_date: date
			});
		} else {
			this.setState({
				setting_free_date_format: date,
				setting_free_date: date
			});
		}
	};
	//5. 엔터키 검색
	_handleKeyDown = e => {
		if (e.key === 'Enter') {
			this.state.startIdx = 0;
			this.state.activePage = 1;
			this.getUserList();
		}
	}
	
	//검색 권한 변경 이벤트
	_handleChange = (e, key) => {
	    // computed property
	    this.setState({[key]: e.target.value
		}, () => {
			// 특정값 실행 함수
			if (key == "setting_service_type") {
				// 사용자 무료체험기간 조회
				this.getActiveServiceList(this.state.target_user_seq, this.state.setting_service_type);
			} else if (key == "payment_service_type") {
				// 사용자 서비스별 멤버십 조회
				this.getServicePaymentTypeList(this.state.target_user_seq, this.state.payment_service_type);
			}
		});
	};
	
	//6.페이징
	handlePageChange(pageNumber) {
		// console.log(`active page is ${pageNumber}`);
		this.setState({activePage: pageNumber});
		this.state.startIdx = (pageNumber * 10) - this.state.rowcnt;
		// console.log("this.state.startIdx :" + this.state.startIdx )
		this.getUserList();
	}  

	handlePopupPageChange(pageNumber) {
		this.setState({popup_activePage: pageNumber});
		this.state.popup_startIdx = (pageNumber * 10) - this.state.popup_rowcnt;
		if (this.state.popup_cls === "0") {
			this.getLoginLog(this.state.target_user_seq);
		} else if (this.state.popup_cls === "1") {
			this.getShopProductsTotalCount(this.state.target_user_seq);
		} else if (this.state.popup_cls === "4") {
			this.getShopTotalSales(this.state.target_user_seq);
		} else if (this.state.popup_cls === "5") {
			this.getShopVisitorsViewTotalCount(this.state.target_user_seq);
		}
	}

	handleSelectBannerShopNameOnChange = (shop_no) => {
		this.state.selected = shop_no;
		this.getBannerTotalCountList(this.state.target_user_seq, shop_no);
	}

	handleSelectRpShopNameOnChange = (shop_no) => {
		this.state.selected = shop_no;
		this.getRpTotalCountList(this.state.target_user_seq, shop_no);
	}

	getBannerShopList = (user_seq) => {
		let banner_total_count_list = [];
		let shop_list = [];
		let shop_name_list = [];

		banner_total_count_list = this.getBannerTotalCountList(user_seq, '');
		banner_total_count_list.then(list => {
			if(list != null && list.length > 0){
				list.map((obj) => {
					if (!shop_name_list.includes(obj.shop_name)) {
						shop_name_list.push(obj.shop_name);
						shop_list.push({"shop_no": obj.shop_no, "shop_name": obj.shop_name})
					}
				});
				this.setState({banner_shop_list : shop_list});
			}
		});
	}

	getRpShopList = (user_seq) => {
		let rp_total_count_list = [];
		let shop_list = [];
		let shop_name_list = [];

		rp_total_count_list = this.getRpTotalCountList(user_seq, '');
		rp_total_count_list.then(list => {
			if(list != null && list.length > 0){
				list.map((obj) => {
					if (!shop_name_list.includes(obj.shop_name)) {
						shop_name_list.push(obj.shop_name);
						shop_list.push({"shop_no": obj.shop_no, "shop_name": obj.shop_name})
					}
				});
				this.setState({rp_shop_list : shop_list});
			}
		});
	}

	// 무료체험기간 설정
	onClickUpdateShopActiveServiceButton = () => {
		this.updateShopActiveService();
	};

	async updateShopActiveService() {
		let resCode = "";
		const body = {
			user_seq: this.state.target_user_seq,
			service_type: this.state.setting_service_type,
			added_type: "Y",	// 관리자 설정시 활성 고정
			added_date: this.state.setting_free_date_format,
		}
		const config = {
            headers: {
                'content-type': 'application/json;'
            }
        }
		await http.post("/rest/admin/updateShopActiveService", body , config)
		.then(res => {
			alert(res.data.msg);
			// 사용자 무료체험기간 조회
			this.getActiveServiceList(this.state.target_user_seq, this.state.setting_service_type);
		}).catch(error => {
			console.log(error);
		});

		return resCode;
	}

	// 사용자 서비스별 멤버십 생성
	onClickServicePaymentTypeCreateButton = () => {
		// 서비스별 멤버십 기본값 설정
		this.servicePaymentTypeCreate();
	}

	async servicePaymentTypeCreate() {
		let resCode = "";
		const body = {
			user_seq : this.state.target_user_seq,
			service_type : this.state.payment_service_type
		}
		const config = {
            headers: {
                'content-type': 'application/json;'
            }
        }
		await http.post("/rest/admin/servicePaymentTypeCreate", body , config)
		.then(res => {
			alert(res.data.msg);
			// 사용자 서비스별 멤버십 조회
			this.getServicePaymentTypeList(this.state.target_user_seq, this.state.payment_service_type);
		}).catch(error => {
			console.log(error);
		});

		return resCode;
	}

	// 사용자 서비스별 멤버십 저장
	onClickServicePaymentTypeSaveButton = () => {
		this.servicePaymentTypeSave();
	};

	async servicePaymentTypeSave() {
		let resCode = "";
		const body = {
			user_seq : this.state.target_user_seq,
			service_type : this.state.payment_service_type,
			user_service_payment_type_list : this.state.user_service_payment_type_list 
		}
		const config = {
            headers: {
                'content-type': 'application/json;'
            }
        }
		await http.post("/rest/admin/servicePaymentTypeSave", body , config)
		.then(res => {
			alert(res.data.msg);
			// 사용자 서비스별 멤버십 조회
			this.getServicePaymentTypeList(this.state.target_user_seq, this.state.payment_service_type);
		}).catch(error => {
			console.log(error);
		});

		return resCode;
	}

	onClickPopup = (user_seq, popup_cls) => {

		// 팝업 세팅
		this.setState({
			target_user_seq: user_seq,
			popup_cls : popup_cls,
			popup_activePage : 1,
			popup_startIdx : 0,
			selected : ''
		}, ()=>{
			if (popup_cls === "0") {
				this.getLoginLog(user_seq);
			} else if (popup_cls === "1") {
				this.getShopProductsTotalCount(user_seq);
			} else if(popup_cls === "2") {
				let shop_no = '';
				this.getBannerTotalCountList(user_seq, shop_no);
				this.getBannerShopList(user_seq);
			} else if(popup_cls === "3") {
				let shop_no = '';
				this.getRpTotalCountList(user_seq, shop_no);
				this.getRpShopList(user_seq);
			} else if(popup_cls === "4") {
				this.getShopTotalSales(user_seq);
			} else if(popup_cls === "5") {
				this.getShopVisitorsViewTotalCount(user_seq);
			} else if (popup_cls == "setting_free_date") {
				this.getActiveServiceList(user_seq, this.state.setting_service_type);
			} else if (popup_cls == "setting_membership") {
				this.getServicePaymentTypeList(user_seq, this.state.payment_service_type);
			}
	
			$("#modalBack").show();
			$("#modal_popup").show();
			$("body").css("background-color", "#a6a6a6");
			$("#modalBack").css("background-color", "#a6a6a6");
			$("#page-wrapper").css("background-color", "#a6a6a6");
		});
	}

	async getLoginLog(user_seq) {
		await http.get("/rest/admin/adminUserLoginLog?user_seq="+user_seq
														+"&startidx="+this.state.popup_startIdx
														+"&rowcnt="+this.state.popup_rowcnt)
					.then(res => {
								this.setState( {login_log_list : res.data["list"], popup_totalCnt : res.data["totalCnt"]} );
								// console.log(this.state.login_log_totalCnt)
								// console.log(this.state.activePage)
								// console.log(this.state.startIdx)
					})
					.catch(error => {
								// console.log (error);
					});
		
	}

	async getShopProductsTotalCount(user_seq) {
		await http.get("/rest/admin/adminUserShopProductsTotalCountList?user_seq="+user_seq
														+"&startidx="+this.state.popup_startIdx
														+"&rowcnt="+this.state.popup_rowcnt)
					.then(res => {
								this.setState( {shop_products_total_count_list : res.data["list"]} );
								// console.log(this.state.login_log_totalCnt)
								// console.log(this.state.activePage)
								// console.log(this.state.startIdx)
					})
					.catch(error => {
								// console.log (error);
					});
		
	}

	async getShopTotalSales(user_seq) {
		await http.get("/rest/admin/adminUserShopTotalSalesList?user_seq="+user_seq
														+"&startidx="+this.state.popup_startIdx
														+"&rowcnt="+this.state.popup_rowcnt)
					.then(res => {
								this.setState( {shop_total_sales_list : res.data["list"]} );
								// console.log(this.state.login_log_totalCnt)
								// console.log(this.state.activePage)
								// console.log(this.state.startIdx)
					})
					.catch(error => {
								// console.log (error);
					});
		
	}

	async getShopVisitorsViewTotalCount(user_seq) {
		await http.get("/rest/admin/adminUserShopVisitorsViewTotalCountList?user_seq="+user_seq
														+"&startidx="+this.state.popup_startIdx
														+"&rowcnt="+this.state.popup_rowcnt)
					.then(res => {
								this.setState( {shop_visit_count_list : res.data["list"]} );
								// console.log(this.state.login_log_totalCnt)
								// console.log(this.state.activePage)
								// console.log(this.state.startIdx)
					})
					.catch(error => {
								// console.log (error);
					});
		
	}

	async getBannerTotalCountList(user_seq, shop_no) {
		await http.get("/rest/admin/adminUserBannerTotalCountList?user_seq=" + user_seq
													+ "&shop_no=" + shop_no
													+ "&startidx=" + this.state.popup_startIdx
													+ "&rowcnt=" + this.state.popup_rowcnt)
				.then(res => {
					this.setState({banner_total_count_list : res.data["list"]});
					this.setState( {bannerTotalCnt : res.data["bannerTotalCnt"]});
				})
				.catch(error => {
					console.log(error);
				});
		return this.state.banner_total_count_list;
	}

	async getRpTotalCountList(user_seq, shop_no) {
		await http.get("/rest/admin/adminUserRpTotalCountList?user_seq=" + user_seq
													+ "&shop_no=" + shop_no
													+ "&startidx=" + this.state.popup_startIdx
													+ "&rowcnt=" + this.state.popup_rowcnt)
			.then(res => {
				this.setState({rp_total_count_list : res.data["list"]});
				this.setState( {rpTotalCnt : res.data["rpTotalCnt"]} );
			})
			.catch(error => {
				console.log(error);
			});
		return this.state.rp_total_count_list;
	}

	async getActiveServiceList(user_seq, service_type) {
		await http.get("/rest/admin/activeServiceList?user_seq=" + user_seq + "&service_type=" + service_type)
			.then(res => {
				this.setState({active_service_list : res.data["list"]});
			})
			.catch(error => {
				console.log(error);
			});
	}

	async getServicePaymentTypeList(user_seq, service_type) {
		await http.get("/rest/admin/servicePaymentTypeList?user_seq=" + user_seq + "&service_type=" + service_type)
			.then(res => {
				this.setState({user_service_payment_type_list : res.data["list"]});
			})
			.catch(error => {
				console.log(error);
			});
	}

	onChangeRemarkModText(e, user_free_trial_extensions_seq) {
		this.setState({
			[user_free_trial_extensions_seq+"_remark"] : e.target.value
		}, () => {
			console.log("##### onChangeRemarkModText", e.target.value)
		})
	}
	onClickRemarkModButton(user_seq) {
		let {modRemarkList} = this.state;
		modRemarkList.push(user_seq);
		this.setState({
			modRemarkList : modRemarkList
		})
	}
	onClickRemarkSaveButton(user_free_trial_extensions_seq) {
		let {modRemarkList} = this.state;
		modRemarkList = modRemarkList.filter(function(item) {
			return item !== user_free_trial_extensions_seq
		})
		this.setState({
			modRemarkList : modRemarkList
		})

		this.modUserRemark(user_free_trial_extensions_seq, this.state[user_free_trial_extensions_seq+"_remark"]);
	}

	textAreaAutoResize(textarea) {
		textarea.style.height = "auto";
        textarea.style.height = (textarea.scrollHeight) + "px";
	}

	handleNoLimitSelectChange = (stateName, selectedOption, user_seq) => {
        let previousOptions = this.state[stateName];
		let currentOptions = selectedOption;
		
		let compareOptions = this.compareOptions(previousOptions, currentOptions);
		// 이전 옵션에서 삭제된 옵션
		let deletedOptions = compareOptions["deletedOptions"];
		// 이전 옵션에서 추가된 옵션
		let addedOptions = compareOptions["addedOptions"];

		if (deletedOptions && deletedOptions.length > 0) {
			const aditional_service_delete_dialog_content = `부가서비스를 정말로 삭제 하시겠습니까?
			<br/><br/>
			[주의]
			<br/>			
			<font style="font-size: 15px">
			결제 이용중인 실시간 서비스를 삭제하는 경우<br/>진열 반복관리가 수동관리로 변경됩니다.
			<br/>
			<br/>- 프리미엄 : 30분, 1시간 이용중인 설정만 수동관리로 변경됨.
			<br/>- 베이직 : 2시간 이상을 이용중인 설정만 수동관리로 변경됨.
			</font>`;
			this.setState({
				aditional_service_delete_dialog_show_state : true,
				aditional_service_delete_dialog_content: aditional_service_delete_dialog_content,
				aditional_service_delete_dialog_button_list: [
					{
						title: "확인",
						type: "1",
						event_method: () => {
							this.setState({
								[stateName]: selectedOption,
								aditional_service_delete_dialog_show_state: false,
							});
							let list = [];
							for (let idx in selectedOption) {
								let obj = {
									user_seq: user_seq,
									function_cd: selectedOption[idx]["code_cd"]
								}
								list[idx] = obj;
							}
							
							console.log("select_additional_function_list", list)
							this.modUserAdditionalFunction(user_seq, deletedOptions, addedOptions);
						}
					},
					{
						title: "아니요",
						type: "0",
						event_method: () => {
							this.setState({
								aditional_service_delete_dialog_show_state: false,
							});
						}
					}
				],
			})
		} else {
			this.setState({
				[stateName]: selectedOption,
			});
			let list = [];
			for (let idx in selectedOption) {
				let obj = {
					user_seq: user_seq,
					function_cd: selectedOption[idx]["code_cd"]
				}
				list[idx] = obj;
			}
			
			console.log("select_additional_function_list", list)
			this.modUserAdditionalFunction(user_seq, deletedOptions, addedOptions);
		}
    };

	// 옵션 비교 함수
	compareOptions(previous, current) {
		// 이전 옵션이 없는 경우 빈 배열로 초기화
		if (!previous) {
			previous = [];
		}
		
		// 코드 비교를 위한 함수
		const isSameOption = (option1, option2) => option1.code_cd === option2.code_cd;

		// 이전 옵션에서 삭제된 옵션
		let deletedOptions = [];
		deletedOptions = previous.filter(
			prevOpt => !current.some(currOpt => isSameOption(prevOpt, currOpt))
		);

		// 현재 옵션에서 추가된 옵션
		let addedOptions = [];
		addedOptions = current.filter(
			currOpt => !previous.some(prevOpt => isSameOption(currOpt, prevOpt))
		);

		return {
			deletedOptions,
			addedOptions
		};
	}

	// 사용자 멤버십 정보 수정
	fn_service_payment_type = (idx, col, value) => {
		console.log(value)
		let user_service_payment_type_list = [...this.state.user_service_payment_type_list];
        user_service_payment_type_list[idx]["state"] = "U";		// 해당 row의 상태값
        user_service_payment_type_list[idx][col] = value;
        this.setState({
            user_service_payment_type_list: user_service_payment_type_list
        });
	}

	async modUserRemark(user_free_trial_extensions_seq, remark) {
		let resCode = "";
		const body = {
			user_free_trial_extensions_seq: user_free_trial_extensions_seq,
			remark: remark,
		}

		const config = {
            headers: {
                'content-type': 'application/json;'
            }
        }
		await http.post("/rest/admin/modUserFreeTrialExtensionRemark", body , config)
		.then(res => {
			resCode = res.code;
		}).catch(error => {
			console.log(error);
		});

		return resCode;
	}

	async modUserAdditionalFunction(user_seq, deletedOptions, addedOptions) {
		let resCode = "";
		const body = {
			user_seq: user_seq,
			deletedOptions: deletedOptions,
			addedOptions: addedOptions,
		}

		const config = {
            headers: {
                'content-type': 'application/json;'
            }
        }

		this.setState({
			show_save_loading_dialog: true,
			save_dialog_content: "적용 중입니다."
		})

		await http.post("/rest/admin/modify/userAdditionalFunction", body , config)
		.then(res => {
			resCode = res.code;
			this.setState({
				show_save_loading_dialog: false,
				basic_dialog_show_state: true,
				basic_dialog_content: "적용 되었습니다.",
				basic_dialog_button_list: [
                    {
                        title: "확인",
                        type: "1",
                        event_method: () => {
                            // 다이얼로그 닫기
                            this.setState({ basic_dialog_show_state: false });
                        }
                    }
                ],
			})
		}).catch(error => {
			console.log(error);
			this.setState({
				show_save_loading_dialog: false,
			})
		});

		return resCode;
	}

	render() {
		
		return (
			<div id='page-wrapper' className="container user_list_container" style={{backgroundColor: '#ffffff', minHeight:"100%"}}>
				<div id="notice_list_component">
					<h4>[Admin]무료체험 연장 신청 목록</h4>
					<br/>
					<div className="filter" style={{width: "1640px", maxWidth:"100%"}}>
						<div className="input-group m-b mobile-width-100">
							<div style={{width: "100%"}}> 
							<label>날짜</label>
								<div className="input-daterange input-group" id="datepicker">
									<DatePicker name="start_date" id="start_date"
										selected={this.state.start_date}
										onSelect={this.handleSelect} //when day is clicked
										onChange={this.handleChangeStartDate} //only when value has changed
										// showTimeSelect
										dateFormat="yyyy-MM-dd"
										autoComplete='off'
									/>
									<div className="input-group-addon" style={{width: "40px",height:"35px", lineHeight: "34px",textAlign:"center"}}>to</div>
									<DatePicker name="end_date" id="end_date"
										selected={this.state.end_date}
										onSelect={this.handleSelect} //when day is clicked
										onChange={this.handleChangeEndDate} //only when value has changed
										// showTimeSelect
										dateFormat="yyyy-MM-dd"
										autoComplete='off'
									/>	
								</div>
							</div>
						</div>
						<div style={{display:"flex", marginBottom: '12px'}}>
							<input type="text" onChange={(e) => {
								// console.log(e.target.value)
								this.setState({ title: e.target.value })
							}} placeholder="검색어를 입력하세요." className="admin_search_input" onKeyDown={this._handleKeyDown} value={this.state.title} style={{flex: "1"}}></input>
							<button className = "greenButton m-r-xs admin_search_btn" onClick={this.onClickSearchListButton} style={{marginRight: "0"}}>검색</button>
							{/* <FileDownloadComponent url={"/rest/admin/adminUserFindAll/excel?"+
																					"&start_date="+this.state.start_date_format
																					+"&end_date="+this.state.end_date_format
																					+"&title="+this.state.title
																					+"&roles="+this.state.roles
																					+"&role_cls="+this.state.role_cls
																					+"&state="+this.state.filter_state
																					+"&scale="+this.state.filter_scale
																					+"&manager="+this.state.filter_manager
																					+"&order_type="+this.state.sorting
																					+"&order_gubun="+this.state.orderGubun
																					}
														   obj=""
														   val1="none" /> */}
						</div>
					</div>
					
					<div className="table">
						<table style={{width:"1640px"}}>
							<colgroup>
								<col width="80px" />	{/* 사용자 순번 */}
								<col width="120px" />	{/* 소속명 */}
								<col width="80px" />	{/* 가입 담당자명 */}
								<col width="50px" />	{/* 설정 */}
								<col width="50px" />	{/* 사용자 멤버십 설정 */}
								<col width="120px" />	{/* 신청일자 */}
								<col width="120px" />	{/* 연락받을 담당자명 */}
								<col width="150px" />	{/* 연락받을 연락처 */}
								<col width="150px" />	{/* 연락받을 이메일 */}
								<col width="200px" />	{/* 사용중인 서비스 */}
								<col width="120px" />	{/* 연동 플랫폼 */}
								<col width="110px" />	{/* 월 주문 수 */}
								<col width="110px" />	{/* 월 페이지뷰 */}
								<col width="270px" />	{/* 비고 */}
							</colgroup>
							<thead>
								<tr>
				        			<td className="user_seq sorting" onClick={this.onClickSorting}>
				        				<p className="card-text">사용자<br/>순번</p>
				        			</td>
				        			<td className="corp_name sorting" onClick={this.onClickSorting}>
				        				<p className="card-text" style={{width:"95%"}}>회사명</p>
				        			</td>
				        			<td className="user_name sorting" onClick={this.onClickSorting}>
				        				<p className="card-text">담당자명</p>
				        			</td>
									<td className="user_state">
				        				<p className="card-text"></p>
				        			</td>
									<td className="user_state">
				        				<p className="card-text"></p>
				        			</td>
				        			<td className="reg_date sorting" onClick={this.onClickSorting} style={{backgroundColor: "#e3f1f1"}}>
				        				<p className="card-text txt_black text_14"><b>무료체험 연장<br/>신청일자</b></p>
				        			</td>
				        			<td className="user_id sorting" onClick={this.onClickSorting} style={{backgroundColor: "#e3f1f1"}}>
				        				<p className="card-text txt_black text_14"><b>안내 받을<br/>담당자명</b></p>
				        			</td>
									<td className="tel_no sorting" onClick={this.onClickSorting} style={{backgroundColor: "#e3f1f1"}}>
										<p className="card-text txt_black text_14"><b>안내 받을<br/>연락처</b></p>
									</td>
									<td className="email sorting" onClick={this.onClickSorting} style={{backgroundColor: "#e3f1f1"}}>
										<p className="card-text txt_black text_14"><b>안내 받을<br/>이메일</b></p>
									</td>
									<td className="code_name sorting" onClick={this.onClickSorting}>
										<p className="card-text">사용중인<br/>서비스</p>
									</td>
									<td className="soltution_type_name sorting" onClick={this.onClickSorting}>
										<p className="card-text">연동<br/>플랫폼</p>
									</td>
									<td className="total_sales sorting" onClick={this.onClickSorting}>
										<p className="card-text">월<br/>주문 수</p>
									</td>
									<td className="visit_total_count sorting" onClick={this.onClickSorting}>
										<p className="card-text">월<br/>페이지뷰</p>
									</td>
									<td className="remark">
				        				<p className="card-text">비고</p>
				        			</td>
				        		</tr>
			        		</thead>
			        		<tbody>
							{this.state.list != null && this.state.list.map(user => (
		                		<tr>
		                			<td>
		                				<p className="card-text">{user.user_seq}</p>
		                			</td>
		                			<td>
		                				<div className="cursor-pointer" onClick={() => this.handleClick(user.user_id)}>
		                					<p className="card-text">{user.corp_name}</p>
		                				</div>
		                			</td>
		                			<td>
		                				<p className="card-text" onClick={() => this.handleClick(user.user_id)}>{user.reg_user_name}</p>
		                			</td>
									<td>
		                				<div className='icon_gear' onClick={() => this.onClickPopup(user.user_seq, "setting_free_date")}></div>
		                			</td>
									<td>
		                				<div className='icon_certificate' onClick={() => this.onClickPopup(user.user_seq, "setting_membership")}></div>
		                			</td>
		                			<td style={{backgroundColor: "rgb(223 250 250 / 50%)"}}>
		                				<p className="card-text"><b>{user.reg_date_format}</b></p>
		                			</td>
									<td style={{backgroundColor: "rgb(223 250 250 / 50%)"}}>
		                				<p className="card-text"><b>{user.user_name}</b></p>
		                			</td>
									<td style={{backgroundColor: "rgb(223 250 250 / 50%)"}}>
										<p className="card-text"><b>{user.tel_no}</b></p>
									</td>
									<td style={{backgroundColor: "rgb(223 250 250 / 50%)"}}>
										<p className="card-text"><b>{user.email}</b></p>
									</td>
									<td>
										<p className="card-text">{user.code_name}</p>
									</td>
									<td>
										<p className="card-text">{user.solution_type_name}</p>
									</td>
		                			<td>
										{
											user.total_sales != '0'
												?	<p className="card-text cursor-pointer" style={{color:"blue"}} onClick={() => this.onClickPopup(user.user_seq, "4")}>{user.total_sales}</p>
												:	<p className="card-text">{user.total_sales}</p>
										}
									</td>
									<td>
										{
											user.visit_total_count != '0'
												?	<p className="card-text cursor-pointer" style={{color:"blue"}} onClick={() => this.onClickPopup(user.user_seq, "5")}>{user.visit_total_count}</p>
												:	<p className="card-text">{user.visit_total_count}</p>
										}
									</td>
									<td>
		                				<p className="card-text">
											{
												this.state.modRemarkList.indexOf(user.user_free_trial_extensions_seq) > -1
												?	<div style={{display:"flex", flexWrap:"nowrap"}}>
														<div>
															<textarea id={"remark_"+user.user_free_trial_extensions_seq} className="user_remark_text_area" onInput={(e) => this.textAreaAutoResize(e.target)} onChange={(e) => this.onChangeRemarkModText(e, user.user_free_trial_extensions_seq)} value={this.state[user.user_free_trial_extensions_seq+"_remark"] ? this.state[user.user_free_trial_extensions_seq+"_remark"] : ""}>
																{this.state[user.user_free_trial_extensions_seq+"_remark"] ? this.state[user.user_free_trial_extensions_seq+"_remark"] : ""}
															</textarea>
														</div>
														<div className="user_remark_save_btn" style={{flex:"none"}} onClick={() => this.onClickRemarkSaveButton(user.user_free_trial_extensions_seq)}>저장</div>
													</div>
											
												: 	<div style={{display:"flex", flexWrap:"nowrap"}}>
														<div>
															<textarea id={"remark_"+user.user_free_trial_extensions_seq} className="user_remark_text_area" onInput={(e) => this.textAreaAutoResize(e.target)} value={this.state[user.user_free_trial_extensions_seq+"_remark"] ? this.state[user.user_free_trial_extensions_seq+"_remark"] : ""} readOnly></textarea>
														</div>
														<div className="user_remark_mod_btn" style={{flex:"none"}} onClick={() => this.onClickRemarkModButton(user.user_free_trial_extensions_seq)}>수정</div>
													</div>
											}
										</p>
		                			</td>
		                		</tr>
							))}
							</tbody>
						</table>
					</div>
					<div className='flexBox' style={{paddingBottom: "50px", fontSize: "18px"}}>
						<Pagination
							activePage={this.state.activePage}
							itemsCountPerPage={this.state.rowcnt}
							totalItemsCount={this.state.totalCnt}
							pageRangeDisplayed={5}
							onChange={this.handlePageChange.bind(this)}
						/>
					</div>
		        </div>

				{/* modal 팝업 */}
                <div id="modal_popup">
                    <div id="modalContent" className="shadow-box modal_admin_user_list" style={{overflow:"auto"}}>
						{
							this.state.popup_cls == "0" ?
								<>
									<h3>로그인 이력</h3>
									<br/><br/>
									<div className="table item_modal_table">
										<table>
											{/* <colgroup>
												<col width="25%" />
												<col width="25%" />
												<col width="25%" />
												<col width="25%" />
											</colgroup> */}
											<thead>	
												<tr>
													<td>
														<p className="card-text">로그인 날짜</p>
													</td>
													<td>
														<p className="card-text">브라우저</p>
													</td>
													<td>
														<p className="card-text">운영체제</p>
													</td>
													<td className="user_name">
														<p className="card-text">디바이스</p>
													</td>
												</tr>
											</thead>
											<tbody style={{display:"block", width:"100%",  height:"calc(57vh - 140px)", overflow:"scroll", marginBottom:"20px"}}>
												{this.state.login_log_list.map((item, idx) => (
													<tr key={item.idx} id = {item.idx}>
														<td>
															<p className="card-text">{item.reg_date}</p>
														</td>
														<td>
															<p className="card-text">{item.browser}</p>
														</td>
														<td>
															<p className="card-text">{item.os}</p>
														</td>
														<td>
															<p className="card-text">{item.device}</p>
														</td>
													</tr>
												))}
											</tbody>
										</table>
									</div>
									<Pagination
										activePage={this.state.popup_activePage}
										itemsCountPerPage={this.state.popup_rowcnt}
										totalItemsCount={this.state.popup_totalCnt}
										pageRangeDisplayed={5}
										onChange={this.handlePopupPageChange.bind(this)}
									/>
								</>
							: this.state.popup_cls == "1" ?
								<>
									<h3>상점별 상품 수</h3>
									<br/><br/>
									<div className="table item_modal_table">
										<table>
											{/* <colgroup>
												<col width="25%" />
												<col width="25%" />
												<col width="25%" />
												<col width="25%" />
											</colgroup> */}
											<thead>	
												<tr>
													<td>
														<p className="card-text">사용자 순번</p>
													</td>
													<td>
														<p className="card-text">플랫폼</p>
													</td>
													<td>
														<p className="card-text">상점 번호</p>
													</td>
													<td>
														<p className="card-text">상점명</p>
													</td>
													<td>
														<p className="card-text">상점 주소</p>
													</td>
													<td>
														<p className="card-text">상품 수</p>
													</td>
													<td>
														<p className="card-text">사용 서비스</p>
													</td>
													<td>
														<p className="card-text">마지막 연동 일자</p>
													</td>
													<td>
														<p className="card-text">연동 상태</p>
													</td>
												</tr>
											</thead>
											<tbody style={{display:"block", width:"100%",  height:"calc(57vh - 140px)", overflow:"scroll", marginBottom:"20px"}}>
												{this.state.shop_products_total_count_list.map((item, idx) => (
													<tr key={item.idx} id = {item.idx}>
														<td>
															<p className="card-text">{item.user_seq}</p>
														</td>
														<td>
															<p className="card-text">{item.solution_type_name}</p>
														</td>
														<td>
															<p className="card-text">{item.shop_no}</p>
														</td>
														<td>
															<p className="card-text">{item.shop_name}</p>
														</td>
														<td>
															<p className="card-text">{item.primary_domain}</p>
														</td>
														<td>
															<p className="card-text">{item.products_count}</p>
														</td>
														<td>
															<p className="card-text">{item.req_service_list}</p>
														</td>
														<td>
															<p className="card-text">{item.sync_date}</p>
														</td>
														<td>
															<p className="card-text">{item.sync_state_name}</p>
														</td>
													</tr>
												))}
											</tbody>
										</table>
									</div>
								</>
							: this.state.popup_cls === "2" ?
								<>
									<h3>배너 설정 수</h3>
									<div className="input-group m-b modal_select_shop">
										<label style={{lineHeight:"35px"}}>상점 선택</label>
										<select id="select_banner_shop" style={{width:"300px", height: "35px",border:"1px solid #c4c4c4", paddingLeft:"10px", paddingRight:"10px", marginBottom:"10px", marginLeft:"15px"}}
												value={this.state.selected} onChange={(e) => this.handleSelectBannerShopNameOnChange(e.target.value)}>
											<option value="">전체</option>
											{this.state.banner_shop_list.map((obj, idx) => (
												<option value={obj["shop_no"]}>{obj["shop_name"]}</option>
											))}
										</select>
									</div>
									<div className="table item_modal_table">
										<table>
											<thead>
											<tr>
												<td className="user_id" style={{marginLeft:"10px"}}>
													<p style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>번호</p>
												</td>
												<td className="user_id" style={{marginLeft:"10px"}}>
													<p style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>사용자 순번</p>
												</td>
												<td className="user_id" style={{marginLeft:"10px"}}>
													<p style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>상점 번호</p>
												</td>
												<td className="user_id" style={{marginLeft:"10px"}}>
													<p style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>상점 이름</p>
												</td>
												<td className="user_id" style={{marginLeft:"10px"}}>
													<p style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>배너명칭</p>
												</td>
												<td className="corp_reg_no">
													<p style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>페이지</p>
												</td>
												<td className="corp_name">
													<p style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>배너사이즈(px)</p>
												</td>
												<td className="business_type">
													<p style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>생성주기</p>
												</td>
												<td className="business_item">
													<p style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>생성시간</p>
												</td>
												<td className="representative_name">
													<p style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>진열기간</p>
												</td>
												<td className="email">
													<p style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>배너노출고객</p>
												</td>
												<td className="email">
													<p style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>디자인선택수</p>
												</td>
												<td className="reg_date">
													<p style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>등록일</p>
												</td>
												<td className="email">
													<p style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>설치 여부</p>
												</td>
												<td className="email">
													<p style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>이용 상태</p>
												</td>
												<td className="email">
													<p style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>결제 만료일</p>
												</td>
												<td className="email">
													<p style={{display:"flex", justifyContent:"center", pointerEvents: "none"}}>결제 남은일</p>
												</td>
											</tr>
											</thead>
											<tbody style={{display:"block", width:"100%",  height:"calc(57vh - 140px)", overflow:"scroll", marginBottom:"20px"}}>
											{this.state.banner_total_count_list.map((obj, idx) => (
												<tr>
													<td>
														<p className="card-text">{this.state.banner_total_count_list.length - idx}</p>
													</td>
													<td>
														<p className="card-text">{obj.user_seq}</p>
													</td>
													<td>
														<p className="card-text">{obj.shop_no}</p>
													</td>
													<td>
														<p className="card-text">{obj.shop_name}</p>
													</td>
													<td>
														<p className="card-text">{obj.iframe_html_name}</p>
													</td>
													<td>
														<p className="card-text">{obj.iframe_page_name}</p>
													</td>
													<td>
														<p className="card-text">{"pc => "+obj.event_banner_pc_width+":"+obj.event_banner_pc_height}<br/>{"mobile => "+obj.event_banner_mobile_width+":"+obj.event_banner_mobile_height}</p>
													</td>
													<td>
														<p className="card-text">{obj.event_cycle+"일"}</p>
													</td>
													<td>
														<p className="card-text">{obj.event_time+"시"}</p>
													</td>
													<td>
														<p className="card-text">{obj.event_period+"일"}</p>
													</td>
													<td>
														<p className="card-text">{obj.event_item_user_type === "15001"
															? "전체고객 기준"
															: "회원/비회원 기준"
														}</p>
													</td>
													<td>
														<p className="card-text">{obj.event_type_cds.split(",").length+"개"}</p>
													</td>
													<td>
														<p className="card-text">{obj.reg_date}</p>
													</td>
													<td>
														{obj.install_yn == "Y" ? <font style={{color:"#000"}}>설치</font>
																				: <font style={{color:"#000"}}>미설치</font>
														}
													</td>
													<td onClick="event.cancelBubble=true" style={{width:"100px"}}>
														<p className="card-text">
															{obj.banner_operated_yn == "Y"
																?
																	<>
																		{obj.request_type == "05002"
																			? <font style={{color:"#10CFC9"}}>결제 이용중</font>
																			: <font style={{color:"#10CFC9"}}>무료체험중</font>
																		}
																	</>
																:
																	<>
																		{obj.request_type_yn == null || obj.request_type_yn == ""
																			? <font style={{color:"red"}}>무료체험만료</font>
																			: <font style={{color:"red"}}>결제만료</font>
																		}
																	</>
															}
														</p>
													</td>
													<td>
														{obj.end_date != null
															?
																<>
																	{obj.remain_date <= 7
																		? <font style={{color:"red"}}>{obj.end_date}</font>
																		: <font>{obj.end_date}</font>
																	}
																</>
															:
																<font>-</font>
														}
													</td>
													<td>
														{obj.end_date != null
															?
																<>
																	{obj.remain_date <= 7
																		?
																			<>
																				{obj.remain_date < 0
																					?
																						<font style={{color:"red"}}>D+{obj.remain_date.toString().substring(1)}일</font>
																					:
																						<font style={{color:"red"}}>D-{obj.remain_date}일</font>
																				}
																			</>
																		:
																			<font>D-{obj.remain_date}일</font>
																	}
																</>
															:
																<font>-</font>
														}
													</td>
												</tr>
											))}
											</tbody>
										</table>
									</div>
								</>
							: this.state.popup_cls === "3" ?
								<>
									<h3>진열 설정 수</h3>
									<div className="input-group m-b modal_select_shop">
										<label style={{lineHeight:"35px"}}>상점 선택</label>
										<select id="select_rp_shop" style={{width:"300px", height: "35px",border:"1px solid #c4c4c4", paddingLeft:"10px", paddingRight:"10px", marginBottom:"10px", marginLeft:"15px"}}
												value={this.state.selected} onChange={(e) => this.handleSelectRpShopNameOnChange(e.target.value)}>
											<option value="">전체</option>
											{this.state.rp_shop_list.map((obj, idx) => (
												<option value={obj["shop_no"]}>{obj["shop_name"]}</option>
											))}
										</select>
									</div>
									<div className="table item_modal_table">
										<table>
											<thead>
												<tr>
													<td className="list_td">번호</td>
													<td className="list_td">사용자 순번</td>
													<td className="list_td">상점 번호</td>
													<td className="list_td">상점 이름</td>
													<td className="list_td">진열 제목</td>
													<td className="list_td">상품선정 최종 일시</td>
													<td className="list_td">상품선정수</td>
													<td className="list_td">노출상품수</td>
													<td className="list_td">상품선정 기준</td>
													<td className="list_td">자동추천</td>
													<td className="list_td" style={{width:"120px", height:"120px"}}>디자인</td>
													<td className="list_td">등록일</td>
													<td className="list_td">상태</td>
													<td className="list_td">진열방식</td>
													<td className="list_td">쇼핑몰</td>
													<td className="list_td">페이지</td>
													<td className="list_td">이용 상태</td>
													<td className="list_td">결제 만료일</td>
													<td className="list_td">결제 남은일</td>
												</tr>
											</thead>
											<tbody style={{display:"block", width:"100%", height:"calc(57vh - 140px)", overflow:"scroll", marginBottom:"20px"}}>
											{this.state.rp_total_count_list != null && this.state.rp_total_count_list.length > 0
												?   <>
													{
														this.state.rp_total_count_list.map((obj, idx) => (
															<tr>
																<td>{this.state.rp_total_count_list.length - idx}</td>
																<td>{obj.user_seq}</td>
																<td>{obj.shop_no}</td>
																<td>{obj.shop_name}</td>
																<td>{obj.display_name}</td>
																<td>{obj.last_mod_date}</td>
																<td>{obj.product_cnt}</td>
																<td>{obj.last_event_product_cnt}</td>
																<td>{obj.item_select_type_name}</td>
																<td>{obj.auto_create_yn_name}</td>
																<td style={{width:"100px", height:"100px"}}>
																	<img src={obj.template_image_url} style={{maxWidth:"100px", maxHeight:"100px"}}/>
																</td>
																<td>{obj.last_reg_date}</td>
																<td>
																	{obj.install_yn == "Y"
																		?   <>
																				{obj.display_yn == "Y" ? <font style={{color:"#10CFC9"}}>진열중</font> : <font>미진열</font>}
																			</>
																		:
																			<>
																				{
																					obj.iframe_page_id == "robomd_cafe24_main_page" || obj.iframe_page_id == "robomd_cafe24_category_page"
																						? <font>설치 완료</font>
																						: <font>설치 전</font>
																				}
																			</>
																	}
																</td>
																<td>{obj.display_type}</td>
																<td>{obj.platform_type_name}</td>
																<td>{obj.iframe_page_name}</td>
																<td>
																	{obj.operated_yn == "Y"
																		?
																			<>
																				{obj.request_type == "05002"
																					? <font style={{color:"#10CFC9"}}>결제 이용중</font>
																					: <font style={{color:"#10CFC9"}}>무료체험중</font>
																				}
																			</>
																		:
																			<>
																				{obj.request_type_yn == null || obj.request_type_yn == ""
																					? <font style={{color:"red"}}>무료체험만료</font>
																					: <font style={{color:"red"}}>결제만료</font>
																				}
																			</>
																	}
																</td>
																<td>
																	{obj.end_date != null
																		?
																			<>
																				{obj.remain_date <= 7
																					? <font style={{color:"red"}}>{obj.end_date}</font>
																					: <font>{obj.end_date}</font>
																				}
																			</>
																		:
																			<font>-</font>
																	}
																</td>
																<td>
																	{obj.end_date != null
																		?
																			<>
																				{obj.remain_date <= 7
																					?
																						<>
																							{obj.remain_date < 0
																								?
																									<font style={{color:"red"}}>D+{obj.remain_date.toString().substring(1)}일</font>
																								:
																									<font style={{color:"red"}}>D-{obj.remain_date}일</font>
																							}
																						</>
																					:
																						<font>D-{obj.remain_date}일</font>
																				}
																			</>
																		:
																			<font>-</font>
																	}
																</td>
															</tr>
														))
													}
												</>
												:
												""
											}
											</tbody>
										</table>
									</div>
								</>
							: this.state.popup_cls == "4" ?
								<>
									<h3>상점별 월 주문 수</h3>
									<br/><br/>
									<div className="table item_modal_table">
										<table>
											{/* <colgroup>
												<col width="25%" />
												<col width="25%" />
												<col width="25%" />
												<col width="25%" />
											</colgroup> */}
											<thead>	
												<tr>
													<td>
														<p className="card-text">사용자 순번</p>
													</td>
													<td>
														<p className="card-text">플랫폼</p>
													</td>
													<td>
														<p className="card-text">상점 번호</p>
													</td>
													<td>
														<p className="card-text">상점명</p>
													</td>
													<td>
														<p className="card-text">상점 주소</p>
													</td>
													<td>
														<p className="card-text">월 주문 수 (일수)</p>
													</td>
													<td>
														<p className="card-text">사용 서비스</p>
													</td>
													<td>
														<p className="card-text">마지막 연동 일자</p>
													</td>
													<td>
														<p className="card-text">연동 상태</p>
													</td>
												</tr>
											</thead>
											<tbody style={{display:"block", width:"100%",  height:"calc(57vh - 140px)", overflow:"scroll", marginBottom:"20px"}}>
												{this.state.shop_total_sales_list.map((item, idx) => (
													<tr key={item.idx} id = {item.idx}>
														<td>
															<p className="card-text">{item.user_seq}</p>
														</td>
														<td>
															<p className="card-text">{item.solution_type_name}</p>
														</td>
														<td>
															<p className="card-text">{item.shop_no}</p>
														</td>
														<td>
															<p className="card-text">{item.shop_name}</p>
														</td>
														<td>
															<p className="card-text">{item.primary_domain}</p>
														</td>
														<td>
															<p className="card-text">{item.total_sales + ' ('+item.total_sales_count+')'}</p>
														</td>
														<td>
															<p className="card-text">{item.req_service_list}</p>
														</td>
														<td>
															<p className="card-text">{item.sync_date}</p>
														</td>
														<td>
															<p className="card-text">{item.sync_state_name}</p>
														</td>
													</tr>
												))}
											</tbody>
										</table>
									</div>
								</>
							: this.state.popup_cls == "5" ?
								<>
									<h3>상점별 월 페이지뷰</h3>
									<br/><br/>
									<div className="table item_modal_table">
										<table>
											{/* <colgroup>
												<col width="25%" />
												<col width="25%" />
												<col width="25%" />
												<col width="25%" />
											</colgroup> */}
											<thead>	
												<tr>
													<td>
														<p className="card-text">사용자 순번</p>
													</td>
													<td>
														<p className="card-text">플랫폼</p>
													</td>
													<td>
														<p className="card-text">상점 번호</p>
													</td>
													<td>
														<p className="card-text">상점명</p>
													</td>
													<td>
														<p className="card-text">상점 주소</p>
													</td>
													<td>
														<p className="card-text">월 페이지뷰 (일수)</p>
													</td>
													<td>
														<p className="card-text">사용 서비스</p>
													</td>
													<td>
														<p className="card-text">마지막 연동 일자</p>
													</td>
													<td>
														<p className="card-text">연동 상태</p>
													</td>
												</tr>
											</thead>
											<tbody style={{display:"block", width:"100%",  height:"calc(57vh - 140px)", overflow:"scroll", marginBottom:"20px"}}>
												{this.state.shop_visit_count_list.map((item, idx) => (
													<tr key={item.idx} id = {item.idx}>
														<td>
															<p className="card-text">{item.user_seq}</p>
														</td>
														<td>
															<p className="card-text">{item.solution_type_name}</p>
														</td>
														<td>
															<p className="card-text">{item.shop_no}</p>
														</td>
														<td>
															<p className="card-text">{item.shop_name}</p>
														</td>
														<td>
															<p className="card-text">{item.primary_domain}</p>
														</td>
														<td>
															<p className="card-text">{item.visit_count + ' ('+item.visit_day_count+')'}</p>
														</td>
														<td>
															<p className="card-text">{item.req_service_list}</p>
														</td>
														<td>
															<p className="card-text">{item.sync_date}</p>
														</td>
														<td>
															<p className="card-text">{item.sync_state_name}</p>
														</td>
													</tr>
												))}
											</tbody>
										</table>
									</div>
								</>
							: this.state.popup_cls == "setting_free_date" ?
								<>
									<h3>서비스별 무료체험기간</h3>
									<br/><br/>
									<div style={{display:"flex", flexWrap:"wrap"}}>
										<div className="input-group m-b mobile-width-50-for-margin-left-10 mobile_margin_left_0" style={{width:"30%"}}>
											<label>대상 서비스</label>
											<select id="roles" style={{width:"100%", height: "35px",border:"1px solid #c4c4c4", paddingLeft:"10px", paddingRight:"10px", marginBottom:"10px"}}
													value={this.state.setting_service_type} onKeyDown={this._handleKeyDown} onChange={(e)=> this._handleChange(e, 'setting_service_type')}>
												<option value="">전체</option>
												<option value="01001">AI배너</option>
												<option value="01002">상품자동진열</option>
												<option value="01005">트렌드 인사이트</option>
												<option value="01010">랭킹 인사이트</option>
												<option value="01009">타겟뷰</option>
											</select>
										</div>
										<div className="input-group m-b mobile-width-100" style={{width:"30%", marginLeft:"10px"}}>
											<div style={{width: "100%"}}> 
											<label>무료체험종료일자 설정</label>
												<div className="input-daterange input-group" id="datepicker" style={{width:'150px'}}>
													<DatePicker name="start_date" id="start_date"
														selected={this.state.setting_free_date}
														onSelect={this.handleSelect} //when day is clicked
														onChange={this.handleChangeSettingFreeDate} //only when value has changed
														// showTimeSelect
														dateFormat="yyyy-MM-dd"
														autoComplete='off'
														locale={ko}
													/>
												</div>
											</div>
										</div>
										<div className="input-group m-b mobile-width-100" style={{width:"30%", marginLeft:"10px"}}>
											<button className="greenButton m-r-xs admin_search_btn" style={{alignSelf:"center"}} onClick={this.onClickUpdateShopActiveServiceButton}>적용</button>
										</div>
										<div className="input-group m-b mobile-width-100" style={{width:"100%"}}>
											{/* 사용여부 */}
										</div>
									</div>
									<div className="table item_modal_table">
										<table>
											{/* <colgroup>
												<col width="25%" />
												<col width="25%" />
												<col width="25%" />
												<col width="25%" />
											</colgroup> */}
											<thead>	
												<tr>
													<td>
														<p className="card-text">사용자 순번</p>
													</td>
													<td>
														<p className="card-text">소속명</p>
													</td>
													<td>
														<p className="card-text">담당자명</p>
													</td>
													<td>
														<p className="card-text">서비스명</p>
													</td>
													<td>
														<p className="card-text">서비스 신청일자</p>
													</td>
													<td>
														<p className="card-text">무료체험 종료일자</p>
													</td>
												</tr>
											</thead>
											<tbody style={{display:"block", width:"100%",  height:"calc(57vh - 140px)", overflow:"scroll", marginBottom:"20px"}}>
												{this.state.active_service_list.map((item, idx) => (
													<tr key={item.idx} id = {item.idx}>
														<td>
															<p className="card-text">{item.user_seq}</p>
														</td>
														<td>
															<p className="card-text">{item.corp_name}</p>
														</td>
														<td>
															<p className="card-text">{item.user_name}</p>
														</td>
														<td>
															<p className="card-text">{item.service_name}</p>
														</td>
														<td>
															<p className="card-text">{item.start_date}</p>
														</td>
														<td>
															<p className="card-text">{item.end_date}</p>
														</td>
													</tr>
												))}
											</tbody>
										</table>
									</div>
								</>
							: this.state.popup_cls == "setting_membership" ?
								<div style={{minHeight:'600px'}}>
									<h3>서비스별 멤버십</h3>
									<br/><br/>
									<div style={{display:"flex", flexWrap:"wrap"}}>
										<div className="input-group m-b mobile-width-50-for-margin-left-10 mobile_margin_left_0" style={{width:"30%"}}>
											<label>대상 서비스</label>
											<select id="roles" style={{width:"100%", height: "35px",border:"1px solid #c4c4c4", paddingLeft:"10px", paddingRight:"10px", marginBottom:"10px"}}
													value={this.state.payment_service_type} onChange={(e)=> this._handleChange(e, 'payment_service_type')}>
												<option value="">전체</option>
												<option value="01001">AI배너</option>
												<option value="01002">상품자동진열</option>
											</select>
										</div>
										<div className="input-group mobile-width-100" style={{width:"30%", marginLeft:"10px"}}>
											<button className="greenButton m-r-xs admin_search_btn" style={{alignSelf:"center"}} onClick={this.onClickServicePaymentTypeCreateButton}>생성</button>
											<button className="greenButton m-r-xs admin_search_btn" style={{alignSelf:"center"}} onClick={this.onClickServicePaymentTypeSaveButton}>적용</button>
										</div>
									</div>
									<div className="table">
										<table style={{width:"2000px"}}>
											<colgroup>
												<col width="80px" />
												<col width="150px" />
												<col width="100px" />
												<col width="150px" />
												<col width="100px" />
												<col width="100px" />
												<col width="150px" />
												<col width="100px" />
												<col width="80px" />
												<col width="80px" />
												<col width="80px" />
												<col width="100px" />
												<col width="80px" />
												<col width="80px" />
												<col width="150px" />
												<col width="100px" />
											</colgroup>
											<thead>	
												<tr>
													<td>
														<p className="card-text">사용자 순번</p>
													</td>
													<td>
														<p className="card-text">소속명</p>
													</td>
													<td>
														<p className="card-text">서비스명</p>
													</td>
													<td>
														<p className="card-text">멤버십 상태</p>
													</td>
													<td>
														<p className="card-text">멤버십 시작일자</p>
													</td>
													<td>
														<p className="card-text">멤버십 종료일자</p>
													</td>
													<td>
														<p className="card-text">요금제 종류</p>
													</td>
													<td>
														<p className="card-text">설정 금액</p>
													</td>
													<td>
														<p className="card-text">설정금액 적용 여부</p>
													</td>
													<td>
														<p className="card-text">추가금액 적용 여부</p>
													</td>
													<td>
														<p className="card-text">다음달 결제대상 여부</p>
													</td>
													<td>
														<p className="card-text">결제 수단</p>
													</td>
													<td>
														<p className="card-text">멤버십 순번</p>
													</td>
													<td>
														<p className="card-text">수동 결제 처리</p>
													</td>
													<td>
														<p className="card-text">결제 번호</p>
													</td>
													<td>
														<p className="card-text">결제 상태</p>
													</td>
												</tr>
											</thead>
											<tbody>
											{/* <tbody style={{display:"block", width:"100%",  height:"calc(57vh - 140px)", overflow:"scroll", marginBottom:"20px"}}> */}
												{this.state.user_service_payment_type_list.map((item, idx) => (
													<tr key={item.idx} id = {item.idx}>
														<td>
															<p className="card-text">{item.user_seq}</p>
														</td>
														<td>
															<p className="card-text">{item.corp_name}</p>
														</td>
														<td>
															<p className="card-text">{item.service_type_name}</p>
														</td>
														<td>
															<select style={{width:"100%", height: "35px",border:"1px solid #c4c4c4", paddingLeft:"10px", paddingRight:"10px", marginBottom:"10px"}}
																	value={item.request_type} onChange={(e)=> this.fn_service_payment_type(idx, "request_type", e.target.value) }>
																{
																	this.state.request_type_list
																	?	this.state.request_type_list.map((obj,idx) => (
																			<option value={obj.code_cd}>{obj.code_name}</option>
																		))
																	: 	null
																}
															</select>
														</td>
														<td>
															<div class="add_list_date_select">
																<DatePicker name="start_date" id="start_date"
																	selected={
																		item.start_date != ''
																		? new Date(item.start_date)
																		: ''
																	}
																	onChange={ (date) => this.fn_service_payment_type(idx, "start_date", date)}
																	placeholderText="시작일자"
																	dateFormat="yyyy-MM-dd"
																	todayButton="오늘"
																	autoComplete="off"
																	showYearDropdown
																	yearDropdownItemNumber={15}
																	scrollableYearDropdown
																	locale={ko}
																/>
															</div>
														</td>
														<td>
															<div class="add_list_date_select">
																<DatePicker name="end_date" id="end_date"
																	selected={
																		item.end_date != ''
																		? new Date(item.end_date)
																		: ''
																	}
																	onChange={ (date) => this.fn_service_payment_type(idx, "end_date", date)}
																	placeholderText="종료일자"
																	dateFormat="yyyy-MM-dd"
																	todayButton="오늘"
																	autoComplete="off"
																	showYearDropdown
																	yearDropdownItemNumber={15}
																	scrollableYearDropdown
																	locale={ko}
																/>
															</div>
														</td>
														<td>
															<select style={{width:"100%", height: "35px",border:"1px solid #c4c4c4", paddingLeft:"10px", paddingRight:"10px", marginBottom:"10px"}}
																	value={item.payment_type} onChange={(e)=> this.fn_service_payment_type(idx, "payment_type", e.target.value) }>
																<option value="">미선택</option>
																{
																	item.service_payment_type_list
																	?	item.service_payment_type_list.map((obj,idx) => (
																			<option value={obj.payment_type}>{obj.payment_type_name}</option>
																		))
																	: 	null
																}
															</select>
														</td>
														<td>
															<input type="number" onChange={(e) => {
																this.fn_service_payment_type(idx, "fix_amount", e.target.value)
															}} className="admin_search_input" style={{width: '120px'}} value={item.fix_amount}></input>
														</td>
														<td>
															{/* <input type="checkbox"
																onClick={e => 
																	(e.target.checked)
																	? this.fn_service_payment_type(idx, "fix_amount_use_yn", "Y")
																	: 
																	this.fn_service_payment_type(idx, "fix_amount_use_yn", "N")
																}
																checked={
																	(item.fix_amount_use_yn == "Y") ? true : false
																}
																style={{display: 'block'}}
															/> */}
															<CheckBox
                                                                    id={"fix_amount_use_yn"+idx}
                                                                    value={idx}
                                                                    checked={ item.fix_amount_use_yn == "Y" }
                                                                    // disabled={ "" }
                                                                    onChangeCheck={ (e) =>
																		(e.target.checked)
																		? this.fn_service_payment_type(idx, "fix_amount_use_yn", "Y")
																		: 
																		this.fn_service_payment_type(idx, "fix_amount_use_yn", "N")
																	}
                                                                />
														</td>
														<td>
															<CheckBox
																id={"add_amount_set_yn"+idx}
																value={idx}
																checked={ item.add_amount_set_yn == "Y" }
																// disabled={ "" }
																onChangeCheck={ (e) =>
																	(e.target.checked)
																	? this.fn_service_payment_type(idx, "add_amount_set_yn", "Y")
																	: 
																	this.fn_service_payment_type(idx, "add_amount_set_yn", "N")
																}
															/>
														</td>
														<td>
															<CheckBox
																id={"next_payment_yn"+idx}
																value={idx}
																checked={ item.next_payment_yn == "Y" }
																// disabled={ "" }
																onChangeCheck={ (e) =>
																	(e.target.checked)
																	? this.fn_service_payment_type(idx, "next_payment_yn", "Y")
																	: 
																	this.fn_service_payment_type(idx, "next_payment_yn", "N")
																}
															/>
														</td>
														<td>
															<p className="card-text">{item.order_type_name}</p>
														</td>
														<td>
															<p className="card-text">{item.payment_amount_seq}</p>
														</td>
														<td>
															<CheckBox
																id={"free_order_yn"+idx}
																value={idx}
																checked={ item.free_order_yn == "Y" }
																// disabled={ "" }
																onChangeCheck={ (e) =>
																	(e.target.checked)
																	? this.fn_service_payment_type(idx, "free_order_yn", "Y")
																	: 
																	this.fn_service_payment_type(idx, "free_order_yn", "N")
																}
															/>
														</td>
														<td>
															<p className="card-text">{item.order_no}</p>
														</td>
														<td>
															<p className="card-text">{item.order_state_name}</p>
														</td>
													</tr>
												))}
											</tbody>
										</table>
									</div>
									<div class="list_admin_side_row_3_1a">
										<span class="i_notice">
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
												<path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
											</svg>
										</span>
										<h3 class="text_12">
											"멤버십 종료일자"로 부터 7일간 유예기간이 있습니다.
										</h3>
									</div>
									<div class="list_admin_side_row_3_1a">
										<span class="i_notice">
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
												<path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
											</svg>
										</span>
										<h3 class="text_12">
											"요금제 종류" 미선택시 "설정 금액"을 적용해 주세요.
										</h3>
									</div>
									<div class="list_admin_side_row_3_1a" style={{marginTop:'5px'}}>
										<span class="i_notice">
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
												<path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
											</svg>
										</span>
										<h3 class="text_12">
											"수동 결제 처리"로 결제 완료 상태로 변경할 수 있습니다.
										</h3>
									</div>
								</div>
							: ''
						}
						<div className="fa fa-close modalClose btn_close_modal"></div>
					</div>
				</div>

				{/* 팝업창 배경 */}
                <div id="modalBack" style={{top: "0", height:"130%"}}></div>

				{
					(this.state.aditional_service_delete_dialog_show_state === true)
						? <ConfirmDialogV2
							content={this.state.aditional_service_delete_dialog_content}
							button_list={this.state.aditional_service_delete_dialog_button_list}
							popup_close={() => {
								this.setState({aditional_service_delete_dialog_show_state: false});
							}}
							width={'auto'}			
						/>
						: ''
				}
				{/* 기본 안내 다이얼로그 */
					(this.state.basic_dialog_show_state === true)
					? <BasicDialogV2
						content={this.state.basic_dialog_content}
						button_list={this.state.basic_dialog_button_list}
					/>
					: ''
				}
				{this.state.show_save_loading_dialog &&
					<SaveDialogV2
						content={this.state.save_dialog_content}
						button_list={[{
							title : "확인",
							display_yn : "N",
							event_method : () => { this.setState({show_save_loading_dialog : false}) }
						}]}
					/>
				}
			</div>
		);
	}
	
}
